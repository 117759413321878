<template>
  <div class="container">
    <div class="row pass-transparency justify-content-center mt-4">
      <div>
        <InfoBox class="mb-4 pass-transparency-info-box">
          <template #slot>
            <h2 class="d-flex pass-transparency-info-box-title">
              <span>Pass transparency is </span>
              <span class="pass-transparency-info-box-title-mode">{{
                !transparencyStatus ? "ON" : "OFF"
              }}</span>
              <CustomSwitch
                class="mx-2"
                type="radio"
                :model-value="!transparencyStatus"
                @change="toggleStatus()"
              ></CustomSwitch>
            </h2>
            <span class="pass-transparency-info-box-content">
              By default Pass allows Teachers and Staff to see all active and
              ended passes, including appointment passes and pass history for
              the whole school building. If a school would like to limit their
              teachers/staff to view only ended passes they are associated with,
              then "Pass Transparency" can be turned off. (Teachers/Staff can
              still see all active passes, but only while they are active on the
              dashboard.) <br /><br />
              Once Pass Transparency is turned off, a school can specify certain
              teachers/staff to be allowed to keep pass transparency, if
              desired. Anyone with admin role access will always have pass
              transparency on.
            </span>
          </template>
        </InfoBox>
      </div>
      <div v-show="transparencyStatus">
        <div>
          <ActionPanel
            show-search
            show-export
            @on-search="searchByQuery"
            @on-export="generateExport"
          ></ActionPanel>
        </div>

        <div class="my-3">
          <div class="status-guides-container d-block">
            <PerPage
              v-if="users"
              :pagination="state.pagination"
              :showing-entries="true"
              @on-page-change="setPerPage"
            />
          </div>
        </div>

        <div class="py-1">
          <DataTable
            v-if="users"
            class="px-3"
            :items="transparencies"
            :fields="dataTableFields"
            :sorter="{ external: true }"
            column-filter
            :no-items-view="{
              noResults: 'No items available',
              noItems: 'No items available'
            }"
            @update:sorter-value="sortByColumn"
          >
            <template #search_column>
              <div class="sort-icon-v3">
                <i class="ri-search-line" @click="toggleSearchByColumn"></i>
              </div>
            </template>
            <template #first_name-filter>
              <input
                v-show="state.filter"
                v-model="state.columnSearchKeys.first_name.value"
                placeholder="Search by first name"
                class="form-control form-control-sm my-2"
                @input="searchByQuery($event.target.value, 'column')"
              />
            </template>
            <template #last_name-filter>
              <input
                v-show="state.filter"
                v-model="state.columnSearchKeys.last_name.value"
                placeholder="Search by last name"
                class="form-control form-control-sm my-2"
                @input="searchByQuery($event.target.value, 'column')"
              />
            </template>
            <template #actions-filter>
              <div v-show="state.filter"></div>
            </template>

            <template #last_name="{ item }">
              <td>
                {{ item.last_name }}
              </td>
            </template>
            <template #first_name="{ item }">
              <td>
                {{ item.first_name }}
              </td>
            </template>

            <template #actions="{ item }">
              <td>
                <Actions
                  :actions="passTransparencyAction"
                  @click-action="handleTableAction($event, item)"
                >
                </Actions>
              </td>
            </template>
          </DataTable>
        </div>
      </div>
      <Pagination
        v-if="
          state.pagination.pages &&
          state.pagination.pages > 1 &&
          !state.isLazyLoadingMode
        "
        class="cs-pagination mt-4"
        :dots="false"
        :double-arrows="true"
        :active-page="state.pagination.activePage"
        :pages="state.pagination.pages"
        align="center"
        size="lg"
        @update:active-page="setActivePage"
      >
      </Pagination>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, computed, inject } from "vue"
import { useStore } from "vuex"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel"
import PerPage from "@/v3components/shared/DataTable/Perpage"
import Actions from "@/v3components/shared/DataTable/Actions"
import Pagination from "@/v3components/shared/DataTable/Pagination"
import helpers from "../../helpers/index"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import download from "@/helpers/downloadCSV"

export default {
  name: "PassTransparencyDataTable",
  components: {
    InfoBox,
    CustomSwitch,
    ActionPanel,
    PerPage,
    Actions,
    Pagination,
    DataTable
  },
  setup() {
    const store = useStore()
    const actionDialog = inject("actionDialog")

    const state = reactive({
      isProcessing: false,
      searchTimeOut: null,
      isLazyLoadingMode: false,
      filter: false,
      pagination: {
        activePage: 1,
        total: 0,
        to: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      filterParams: {
        sort_query: "",
        searchQuery: ""
      },
      columnSearchKeys: {
        first_name: {
          value: "",
          column: ["user.first_name"],
          sortBy: "user.first_name"
        },
        last_name: {
          value: "",
          column: ["user.last_name"],
          sortBy: "user.last_name"
        }
      }
    })

    const dataTableFields = [
      { key: "first_name", label: "First Name", type: "tabular" },
      { key: "last_name", label: "Last Name", type: "tabular" },
      { key: "actions", label: "Actions", type: "tabular" }
    ]

    const passTransparencyAction = [
      {
        label: "Remove",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "remove"
      }
    ]

    const users = computed(() => store.getters["passes/transparencies"])

    const transparencyStatus = computed(
      () => store.getters["passes/transparencyStatus"]
    )

    const transparencies = computed(() =>
      users.value.map((e) => ({
        ...e,
        first_name: e.user.first_name,
        last_name: e.user.last_name
      }))
    )

    onMounted(() => {
      initLazyLoading()
      getTableData()
    })

    const toggleStatus = () => {
      store.dispatch("passes/toggleTransparency", {
        status: !transparencyStatus.value
      })
    }

    const getTableData = (page, noLoader, isLazyLoadingMode) => {
      state.isProcessing = !noLoader
      state.pagination.activePage = page ? page : 1
      const params = {
        page: state.pagination.activePage,
        per_page: state.pagination.per_page.value
      }
      if (state.filterParams.search_query) {
        params.search_query = state.filterParams.search_query
      }
      if (state.filterParams.sort_query) {
        params.sort = state.filterParams.sort_query
      }
      store
        .dispatch("passes/getTransparencies", params)
        .then((response) => {
          const data = response.data.data
          if (isLazyLoadingMode) {
            store.commit(
              "passes/PUSH_TRANSPARENCIES",
              data.transparency_users.data
            )
            store.commit(
              "passes/SET_TRANSPARENCY_STATUS",
              data.transparency_flags.exists
            )
          } else {
            store.commit(
              "passes/SET_TRANSPARENCIES",
              data.transparency_users.data
            )
            store.commit(
              "passes/SET_TRANSPARENCY_STATUS",
              data.transparency_flags.exists
            )
          }
          if (data.transparency_users) {
            state.pagination.total = data.transparency_users.total
            state.pagination.from = data.transparency_users.from
            state.pagination.to = data.transparency_users.to
            state.pagination.pages = Math.ceil(
              data.transparency_users.total / data.transparency_users.per_page
            )
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
          state.isProcessing = false
        })
        .catch(() => {
          state.isProcessing = false
        })
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        getTableData()
      }
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      state.filterParams.sort_query = `${columnName}:${
        column.asc ? "asc" : "desc"
      }`
      getTableData()
    }

    const toggleSearchByColumn = () => {
      state.filter = !state.filter
    }

    const generateExport = () => {
      const params = {
        page: state.pagination.activePage,
        per_page: [25, 50, 100].includes(state.pagination.per_page.value)
          ? state.pagination.per_page.value
          : "all"
      }
      store.dispatch("passes/exportTransparencies", params).then((response) => {
        if (response.data) {
          download.CSVExport(response.data, "pass_transparency")
        }
      })
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      getTableData(state.pagination.activePage)
      helpers.scrollToTop()
    }

    const searchByQuery = (value, type) => {
      clearInterval(state.searchTimeOut)
      state.searchTimeOut = setTimeout(() => {
        handleQuerySearch(value, type)
      }, 500)
    }

    const handleQuerySearch = (value, type) => {
      const query = value.toString()
      if (
        query.replace(/\s/g, "").length > 0 ||
        query.replace(/\s/g, "").length === 0
      ) {
        state.filterParams.search_query = ""
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  state.filterParams.search_query =
                    state.filterParams.search_query +
                    `${col}:"${columnData.value}", `
                })
              }
            }
          }
          state.filterParams.search_query =
            state.filterParams.search_query.slice(0, -2)
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          state.filterParams.search_query = query ? '"' + query + '"' : query
        }
        getTableData()
      }
    }

    const resetSearchQuery = () => {
      state.filterParams.search_query = ""
      state.columnSearchKeys = {
        globalQuery: "",
        first_name: {
          value: "",
          column: ["user.first_name"],
          sortBy: "user.first_name"
        },
        last_name: {
          value: "",
          column: ["user.last_name"],
          sortBy: "user.last_name"
        }
      }
    }

    const handleTableAction = (event, item) => {
      if (event.action == "remove") {
        actionDialog.open(removePassTransparency, {
          args: item,
          props: { danger: true, title: "Remove", question: "Are you sure?" }
        })
      }
    }

    const removePassTransparency = (transparency) => {
      store
        .dispatch("passes/deleteTransparency", {
          transparency_id: transparency.id
        })
        .then(() => {
          getTableData()
        })
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            transparencies.value &&
            transparencies.value.length &&
            state.pagination.total > transparencies.value.length
          ) {
            if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
              state.pagination.activePage = state.pagination.activePage + 1
              getTableData(state.pagination.activePage, false, true)
            }
          }
        }
      }
    }

    return {
      state,
      dataTableFields,
      passTransparencyAction,
      users,
      transparencyStatus,
      transparencies,
      toggleStatus,
      getTableData,
      setPerPage,
      sortByColumn,
      toggleSearchByColumn,
      generateExport,
      setActivePage,
      searchByQuery,
      handleTableAction
    }
  }
}
</script>
